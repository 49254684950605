/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use '@angular/material' as mat;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/css";
@import "~swiper/css/pagination";

@include mat.core();
$md-brewbill: (
        50 : #fffae0,
        100 : #fff3b3,
        200 : #ffeb80,
        300 : #ffe34d,
        400 : #ffdd26,
        500 : #fddb31,
        600 : #ffd300,
        700 : #ffcd00,
        800 : #ffc700,
        900 : #ffbe00,
        A100 : #ffffff,
        A200 : #fffbf2,
        A400 : #ffecbf,
        A700 : #ffe5a6,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$my-custom-primary: mat.define-palette($md-brewbill);
$my-custom-accent: mat.define-palette(mat.$pink-palette, 100, 500, A100);
$my-custom-warn: mat.define-palette(mat.$lime-palette);

$my-custom-theme: mat.define-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

@include mat.all-component-themes($my-custom-theme);

@import 'brewbill-theme';

.child-item {
  margin-left: 15px !important;
}

.sub-item {
  border-left: 1px solid #fff;
  padding-left: 5px;
}

.credit-card > .action-sheet-button-inner {
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto 25px;
  padding-left: 45px;
}

.american-express {
  background-image: url('../assets/card-logo/amex.png');
}

.diners-club {
  background-image: url('../assets/card-logo/diners_club.png');
}

.discover {
  background-image: url('../assets/card-logo/discover.png');
}

.jcb {
  background-image: url('../assets/card-logo/jcb.png');
}

.mastercard {
  background-image: url('../assets/card-logo/master.png');
}

.unionpay {
  background-image: url('../assets/card-logo/unionPay.png');
}

.visa {
  background-image: url('../assets/card-logo/visa.png');
}

.amex > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/amex.png');
}

.diners-club > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/diners_club.png');
}

.discover > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/discover.png');
}

.jcb > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/jcb.png');
}

.mastercard > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/master.png');
}

.unionpay > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/unionPay.png');
}

.visa > .action-sheet-button-inner {
  background-image: url('../assets/card-logo/visa.png');
}

.full-screen-modal {
  --min-height: 100vh;
  --min-width: 100vw;
}

.compressed-modal {
  --max-height: 270px;
}

button:focus {
  outline: none;
}

.order-in-toast {
  font-size: 12vh;
  text-align: center;
  --min-height: 12vh;
}

.waiting-orders-toast {
  font-size: 5vh;
  text-align: center;
  --min-height: 10vh;
}

.cash-drawer-modal,
.report-modal,
.menu-modal {
  --min-width: 90vw;
  --min-height: 90vh;
}

.present-card-modal {
  --min-width: min(90vw, 800px);
  --min-height: min(90vh, 650px);
}

.credit-card-modal {
  --width: 100vw;
  --min-height: 100vh;
}

.inventory-modal,
.select-payment-modal,
.confirm-tab-modal,
.menu-item-modal {
  --min-height: 90vh;
}

@supports (-webkit-touch-callout: none) {
  .inventory-modal,
  .select-payment-modal,
  .confirm-tab-modal,
  .menu-item-modal {
    --min-height: -webkit-fill-available;
  }
}

ion-footer {
  background-color: var(--ion-background-color);
  z-index: 9999;
  height: fit-content;
}

.item-checkbox-checked {
  --color: var(--ion-text-color) !important;
}

.bb-action-sheet {
  --button-color: var(--ion-text-color);
  --button-color-activated: var(--ion-text-color);
  --button-color-focused: var(--ion-text-color);
  --button-color-hover: var(--ion-text-color);
  --button-color-selected: var(--ion-text-color);
}

.swiper-slide {

  @media (max-width: 400px) {
    width: 200px;
  }

  @media (min-width: 400px) {
    width: 270px;
  }
}

.add-card-modal {
  --max-height: 380px;
}

.select-card-modal {
  --max-height: 600px;
}

.quick-input-modal {
  --max-height: 250px;
}

.pickup-location-item {
  --button-color: var(--ion-text-color);
  --button-color-activated: var(--ion-text-color);
  --button-color-focused: var(--ion-text-color);
  --button-color-hover: var(--ion-text-color);
  --button-color-selected: var(--ion-text-color);
}

ion-action-sheet.pickup-location-item .action-sheet-button-inner::first-line {
  font-size: .9rem;
  color: var(--ion-color-step-600);
}

ion-action-sheet.pickup-location-item .action-sheet-button-inner {
  display: block;
}

.filter-action-button-container {
  display: flex;
  align-items: center;
  justify-content: start;
}

.filter-action-button-container > ion-button {
  height: 44px;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.floating-filters {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: var(--ion-background-color);
}

.filters {
  flex: 0 1 auto;
}

.page-contents {
  flex: 1 auto;
  overflow-y: hidden;
}

.mat-column-align-end > div {
  text-align: right;
  justify-content: flex-end;
}

.table-summary-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  font-weight: 700;
}

.footer-button-grid ion-col {
  padding: 0 4px;
}

.mat-table tfoot {
  position: sticky;
  bottom: 0;
}

.color-select-popover {
  --width: 320px;
}

.comp-container {
  width: 100%;
  display: flex;
}

.comp-label {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.comp-label p {
  margin-top: 0;
  color: var(--ion-color-success);
}

.comp-actions {
  display: inline-block;
  flex-shrink: 1;
  padding-left: 10px;
}

.sticky-filter {
  position: sticky;
  background-color: var(--ion-background-color);
  top: 0;
  z-index: 999;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}

.modal-message {
  font-size: 22px;
}
