@font-face {
  font-family: 'Relative Mono Pro';
  src: local('Relative Mono Pro'),
  local('Relative-Mono-Pro'),
  url('../assets/fonts/relative-mono-11-pitch-pro.otf') format('opentype')
}

body {
  background-color: var(--ion-background-color);
}

.item-has-focus .sc-ion-label-md-h, .item-has-focus .sc-ion-label-md-h {
  color: var(--ion-text-color) !important;
}

.card-content-md,
.card-content-ios {
  color: var(--ion-text-color) !important;
}

ion-segment-button {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --background-checked: var(--ion-color-success);
  --indicator-color: var(--ion-color-success);
  --color-checked: var(--ion-color-success-contrast);
}

ion-segment.secondary > ion-segment-button {
  --background-checked: var(--ion-color-secondary);
  --indicator-color: var(--ion-color-secondary);
  --color-checked: var(--ion-color-secondary-contrast);
}

ion-card-title > ion-icon {
  float: right;
  cursor: pointer;
  padding: 10px;
  margin-top: -10px;
  margin-right: -10px;
}

.enable-clicks {
  pointer-events: visible !important;
}

a {
  color: var(--ion-color-secondary);
  cursor: pointer;
  text-decoration: none;
}

ion-card-header a {
  color: var(--ion-color-contrast);
}

.upload-image {
  cursor: pointer;
}

ion-icon {
  font-size: 18px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

ion-fab-button ion-icon {
  font-size: 24px;
  margin-top: unset;
  margin-bottom: unset;
}

table {
  width: 100%;
}

.pointer {
  cursor: pointer;
}


ion-loading,
.bb-loading-spinner {
  --spinner-color: var(--ion-color-secondary);
}

ion-row,
ion-item,
ion-list {
  --ion-background-color: var(--ion-inner-background-color);
}

ion-item {
  --border-color: #d3d3d3;
}

.alert-wrapper.sc-ion-alert-md,
.card-content-md,
.card-content-ios {
  background-color: var(--ion-inner-background-color);
}

.stepper-filter-content {
  min-height: 324px;
  max-height: calc(100% - 56px);
}

.tags-filter-content {
  height: 180px;
}

.unavailable {
  text-decoration: line-through;
}

.selectable-item {
  cursor: pointer;
}

.searchable-list {
  max-height: 500px;
  overflow-y: auto;
}

.mat-step-header {
  min-width: 120px;
}

.mat-step-icon.mat-step-icon-state-number {
  background-color: rgba(0, 0, 0, 0.2);
}

.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  background-color: var(--ion-color-primary);
}

.mat-horizontal-stepper-header-container {
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: var(--ion-background-color);
  z-index: 3;
}

span {
  white-space: pre-wrap;
}

.inner-list {
  padding-left: 16px;
  padding-top: 5px;
  float: left;
  clear: both;
}

.break-lines,
p {
  white-space: pre-wrap;
}

.list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.tri-button {
  width: calc(33% - 4px);
}

.paired-button-container {
  margin-left: 10px;
  margin-right: 10px;
}

.paired-button {
  height: 55px;
  font-size: 20px;
  margin-bottom: 10px;
  width: calc(50% - 4px);
}

.single-button {
  width: 100%;
}

.locked {
  margin-left: 25%;
  width: 50%;
}

ion-toast {
  --border-radius: 4px;
}

ion-button {
  --border-radius: 4px;
}

ion-card {
  border-radius: 4px;
  margin: 10px;
  margin-inline: 10px;
  margin-inline-end: 10px;
  margin-inline-start: 10px;
}

ion-menu-button {
  font-size: 24px;
}

ion-list-header {
  font-weight: 400;
  font-size: unset !important;
}

ion-list-header > ion-button {
  margin-bottom: auto;
  margin-top: auto;
}

ion-card-content {
  padding: 13px 16px;
}

ion-popover {
  --min-width: 250px !important;
  border-radius: 4px;
}

.picker-prefix,
.picker-suffix,
.picker-opt.picker-opt-selected,
.alert-button.sc-ion-alert-md,
.alert-button.sc-ion-alert-ios,
.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md,
.picker-button.sc-ion-picker-ios,
.picker-button.ion-activated.sc-ion-picker-ios {
  color: var(--ion-color-secondary);
}

ion-button {
  margin-top: auto;
  margin-bottom: auto;
}

.alert .alert-wrapper {
  background-color: var(--ion-inner-background-color);
}

.alert .secondary {
  color: var(--ion-text-color);
}

.sc-ion-label-ios-s > p {
  color: var(--ion-color-step-600, #666);
}

ion-reorder {
  margin-left: 16px;
}

ion-icon[slot="start"] {
  margin-right: 0;
  margin-left: -20px;
  padding: 10px;
}

ion-icon[slot="end"] {
  margin-right: -10px;
  margin-left: 0;
  padding: 10px;
}

ion-card-title {
  font-size: 18px;
}

bb-user-summary-item,
bb-tab-summary-item,
bb-order-summary-item {
  display: flex;
}

ion-item > .card-image {
  height: 50px;
  padding-right: 0;
  margin-right: 5px;
  padding-top: 0;
}

.pill-success {
  display: inline-block;
  color: var(--ion-color-success);
}

.pill-secondary {
  display: inline-block;
  color: var(--ion-color-secondary);
}

.pill-tertiary {
  display: inline-block;
  color: var(--ion-color-tertiary);
}

.pill-warning {
  display: inline-block;
  color: var(--ion-color-warning);
}

.pill-danger {
  display: inline-block;
  color: var(--ion-color-danger);
}

.pill-light {
  display: inline-block;
  color: var(--ion-color-light);
  /*border: solid 1px var(--ion-color-light);*/
  border-radius: 5px;
  margin-right: 5px;
}

.pill-dark {
  display: inline-block;
  color: var(--ion-color-dark);
  border: solid 1px var(--ion-color-dark);
  border-radius: 5px;
  margin-right: 5px;
}

.action-button {
  margin-right: 10px;
}

.deposit {
  margin-left: 10px;
  border: solid 1px var(--ion-color-tertiary);
  padding: 0 5px;
  border-radius: 5px;
  display: inline-block;
  color: var(--ion-color-tertiary);
}

.bb-marker-label {
  margin-top: 70px;
  white-space: pre-line;
}

.marker-none,
.marker {
  font-size: 70px;
  padding: 5px 5px 20px 5px;
  margin: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 200;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 10px;
}

.marker {
  padding: 5px 5px 20px 40px;
  background-image: url('/assets/blackLogoOnly.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position-y: calc(100% - 10px);
  background-position-x: 10px;
}

.marker-disabled,
.marker:disabled {
  opacity: .5;
}

.page-header {
  margin-top: 10px;
}

.round {
  width: 50px;
  height: 50px;
  --border-radius: 100% !important;
}

bb-accordion {
  display: block;
}

bb-expandable {
  display: block;
}

.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

.large-modal {
  --height: 80vh;
  --width: 80vw;
}

.large-modal .map-container {
  height: calc(80vh - 220px);
  width: 100%;
}

.image-placeholder {
  font-size: 60px;
}

.location-container {
  display: flex;
}

.location-title {
  flex-shrink: 1;
  margin-right: 10px;
}

.location-icon {
  font-size: 30px;
}

.location-icon .icon-inner {
  padding-top: 5px;
}

.footer-button {
  height: 55px;
  font-size: 20px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: 5%;
}

.full-footer-button {
  height: 55px;
  font-size: 20px;
  margin-bottom: 10px;
  width: 100%;
}

/* ---- grid-item ---- */
.grid-max-3 > bb-masonry-grid-item,
.grid-max-3 > .size-md {
  @media (min-width: 0) and (max-width: 799px) {
    width: 100%;
  }
  @media (min-width: 800px) and (max-width: 1599px) {
    width: 50%;
  }
  @media (min-width: 1600px) {
    width: 33.333%;
  }
}

.grid-max-3 > .grid-sizer,
.grid-max-3 > .size-xs {
  @media (min-width: 0) and (max-width: 799px) {
    width: 50%;
  }
  @media (min-width: 800px) and (max-width: 1599px) {
    width: 25%;
  }
  @media (min-width: 1600px) {
    width: 16.667%;
  }
}

.grid-max-3 > .size-lg {
  width: 100%;
}

.grid-max-2 > .grid-sizer,
.grid-max-2 > bb-masonry-grid-item {
  @media (min-width: 0) and (max-width: 799px) {
    width: 100%;
  }
  @media (min-width: 800px) {
    width: 50%;
  }
}

.grid-start-tab > .grid-sizer,
.grid-start-tab > bb-masonry-grid-item {
  @media (min-width: 0) and (max-width: 1100px) {
    width: 100%;
  }
  @media (min-width: 1100px) and (max-width: 1599px) {
    width: 50%;
  }
  @media (min-width: 1600px) {
    width: 33.33%;
  }
}

bb-masonry-grid-item {
  float: left;
}

bb-masonry-grid-item ion-card {
  margin: 0;
}

.status-all {
  --background-checked: var(--ion-color-dark);
  --indicator-color: var(--ion-color-dark);
  --color-checked: var(--ion-color-dark-contrast);
}

.status-received {
  --background-checked: var(--ion-color-warning);
  --indicator-color: var(--ion-color-warning);
  --color-checked: var(--ion-color-warning-contrast);
}

.status-preparing {
  --background-checked: var(--ion-color-tertiary);
  --indicator-color: var(--ion-color-tertiary);
  --color-checked: var(--ion-color-tertiary-contrast);
}

.status-ready {
  --background-checked: var(--ion-color-primary);
  --indicator-color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary-contrast);
}

.status-delivered {
  --background-checked: var(--ion-color-success);
  --indicator-color: var(--ion-color-success);
  --color-checked: var(--ion-color-success-contrast);
}

.status-canceled {
  --background-checked: var(--ion-color-danger);
  --indicator-color: var(--ion-color-danger);
  --color-checked: var(--ion-color-danger-contrast);
}

bb-order-item-summary,
bb-compact-order-item {
  width: 100%;
}

.success-alert {
  border-radius: 5px;
  color: var(--ion-color-success-contrast);
  padding: 10px;
  background-color: var(--ion-color-success);
}

.compact-modal {
  --height: 250px;
}

bb-edit-credit-card {
  height: 100%;
}

.highlight-success {
  animation: pulse-highlight-success 1.5s infinite;
}

@keyframes pulse-highlight-success {

  0% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 0 var(--ion-color-success);
  }

  70% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


.highlight-primary {
  animation: pulse-highlight-primary 1.5s infinite;
}

@keyframes pulse-highlight-primary {

  0% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 0 var(--ion-color-primary);
  }

  70% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 12%) 0 1px 5px 0, 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
