// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #fddb31;
  --ion-color-primary-rgb: 255, 215, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0bd00;
  --ion-color-primary-tint: #ffdb1a;

  --ion-color-secondary: #c04949;
  --ion-color-secondary-rgb: 192, 73, 73;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #a94040;
  --ion-color-secondary-tint: #c65b5b;

  --ion-color-tertiary: #9e3dff;
  --ion-color-tertiary-rgb: 158,61,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #8b36e0;
  --ion-color-tertiary-tint: #a850ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #52e5ff;
  --ion-color-warning-rgb: 82,229,255;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #48cae0;
  --ion-color-warning-tint: #63e8ff;

  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255,0,0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e00000;
  --ion-color-danger-tint: #ff1a1a;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #ffffff;
  --ion-inner-background-color: #ffffff;
  --ion-text-color: #000000;
  --ion-color-step-150: #bebebe;
  --ion-color-step-600: #979797;
  --ion-color-step-850: #383a3e;

  --ion-background-color-rgb: 246, 234, 181;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-backdrop-opacity: .4;
  --ion-font-family: Tahoma, sans-serif;

  --ion-toolbar-background: var(--ion-background-color);
  --ion-padding: 8px;
  --ion-grid-column-padding: 2px;

  ion-card {
    --color: #383a3e;
  }

  ion-picker {
    --background: var(--ion-inner-background-color);
  }
}
